<template>
  <div>
    <loader :loading="requisicaoAberta"></loader>
    <CForm v-on:submit.prevent="dimensionarGeotecnico">
      <CRow>
        <CCol sm="7">
          <CRow>
            <CCol sm="6">
              <CSelect
                label="Método"
                :options="fundacao==1 ? metodosSapata : metodosTubulao"
                :value.sync="$v.opcoesCalculo.metodo.$model"
                :isValid="verificarCampo('metodo','opcoesCalculo')"
              ></CSelect>
            </CCol>
            <CCol sm="6">
              <CSelect
                label="Sondagem"
                :options="opcoesSondagem"
                :value.sync="$v.opcoesCalculo.sondagem.$model"
                @change="selecionarSondagem"
                :isValid="verificarCampo('sondagem','opcoesCalculo')"
                :invalidFeedback="obtenhaMensagem(2016)"
              ></CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Cota de Assentamento"
                type="number"
                append="m"
                :value.sync="$v.opcoesCalculo.cota_final.$model"
                :isValid="verificarCampo('cota_final','opcoesCalculo')"
              ></CInput>
            </CCol>
            <CCol sm="6" v-if="opcoesCalculo.metodo==104 || opcoesCalculo.metodo==204">
              <CInput
                label="Coeficiente"
                type="number"
                :value.sync="$v.opcoesCalculo.coeficiente.$model"
                :isValid="verificarCampo('coeficiente','opcoesCalculo')"
              ></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInputCheckbox label="Bulbo de Pressão" :checked.sync="opcoesCalculo.bulbo" />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" v-if="opcoesCalculo.metodo==105 || opcoesCalculo.metodo==205">
              <CInput
                label="Carga"
                type="number"
                append="kPa"
                :value.sync="$v.opcoesCalculo.carga.$model"
                :isValid="verificarCampo('carga','opcoesCalculo')"
              ></CInput>
            </CCol>
            <CCol sm="6" v-if="fundacao==2">
              <CInput
                label="Sobre Carga"
                type="number"
                append="kPa"
                :value.sync="$v.opcoesCalculo.sobrecarga.$model"
                :isValid="verificarCampo('sobrecarga','opcoesCalculo')"
              ></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="8">
              <CFormGroup description="Limite do NSPT">
                <label slot="label">Limite SPT</label>
                <vue-slider
                  slot="input"
                  v-model="opcoesCalculo.limite_spt"
                  :min="0"
                  :max="100"
                  :tooltip="'active'"
                  :enable-cross="false"
                ></vue-slider>
              </CFormGroup>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="5">
          <PerfilSondagem
            v-if="opcoesCalculo.sondagem > 0"
            :sondagemSPT="sondagemEscolhida.nspt"
            :altura="alturaPerfil | inteiro"
            :nivel-agua="sondagemEscolhida.nivelagua | decimal"
            class="perfil-sondagem"
          ></PerfilSondagem>
        </CCol>
      </CRow>
    </CForm>
    <CModal :show="janelaResultadoDimensionamento">
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Geotécnico
          <CButtonClose
            @click="janelaResultadoDimensionamento = false"
            buttonClasses="text-muted close"
          />
        </CCardHeader>
      </template>
      <template slot="body-wrapper" v-if="resultadoDimensionamento.metodo_texto">
        <loader :loading="requisicaoAberta"></loader>
        <CCardBody>
          <CForm>
            <CInput label="Identificação" :value.sync="opcoesCalculo.identificacao" />
          </CForm>
          <CRow>
            <CCol sm="6" class="text-right">Método</CCol>
            <CCol sm="6" class="text-left">{{resultadoDimensionamento.metodo_texto}}</CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="6" class="text-right">Cota Informada</CCol>
            <CCol sm="6" class="text-left">{{opcoesCalculo.cota_final}} m</CCol>
          </CRow>
          <hr v-if="opcoesCalculo.bulbo" />
          <CRow v-if="opcoesCalculo.bulbo">
            <CCol sm="6" class="text-right">Pilar Considerado</CCol>
            <CCol sm="6" class="text-left">{{resultadoDimensionamento.pilar.identificacao}}</CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="6" class="text-right">SPT Considerado</CCol>
            <CCol sm="6" class="text-left">{{resultadoDimensionamento.spt_considerado.nspt}}</CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="6" class="text-right">Coeficiente</CCol>
            <CCol sm="6" class="text-left">{{resultadoDimensionamento.coeficiente}}</CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="6" class="text-right">Tensão(kPa)</CCol>
            <CCol sm="6" class="text-left">{{resultadoDimensionamento.tensao | precisao(2)}} kPa</CCol>
          </CRow>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="salvarDimensionamento">
            <CIcon name="cil-save"></CIcon>&nbsp;Salvar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
const isPositive = value =>
  value == null || value.trim == "" || parseFloat(value) > 0;
const cotaMaximaAssentamento = (valor, vm) => vm.cotaMaximaAssentamento > valor;
import { required, requiredIf, between } from "vuelidate/lib/validators";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import Axios from "axios";
import PerfilSondagem from "./../Sondagem/PerfilSondagem";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "SBFomrularioGeotecnicoDireta",
  components: { Loader, PerfilSondagem, VueSlider },
  mixins: [mixin],
  data() {
    return {
      requisicaoAberta: false,
      metodo: [],
      metodosSapata: [
        { value: "0", label: "Selecione o método" },
        { value: "101", label: "U. R. Alonso" },
        { value: "102", label: "M. C. Moraes" },
        { value: "103", label: "W. L. Costa" },
        { value: "104", label: "Coeficiente" },
        { value: "105", label: "Carga Resistente" }
      ],
      metodosTubulao: [
        { value: "0", label: "Selecione o método" },
        { value: "201", label: "U. R. Alonso" },
        { value: "202", label: "M. C. Moraes" },
        { value: "203", label: "W. L. Costa" },
        { value: "204", label: "Coeficiente" },
        { value: "205", label: "Carga Resistente" }
      ],
      opcoesSondagem: [],
      opcoesCalculo: {
        identificacao: "",
        fundacao: this.fundacao,
        sondagem: 0,
        metodo: 0,
        bulbo: false,
        cota_final: null,
        coeficiente: null,
        carga: null,
        limite_spt: [3, 25],
        cotaMaximaPermitida: 0,
        dimensionado: false,
        sobrecarga: null
      },
      alturaPerfil: 570,
      sondagemEscolhida: {
        nspt: [],
        nivelagua: 0
      },
      janelaResultadoDimensionamento: false,
      resultadoDimensionamento: {}
    };
  },
  validations: {
    opcoesCalculo: {
      sondagem: { required, isPositive },
      metodo: { required, between: between(101, 205) },
      identificacao: {
        required: requiredIf(opcoesCalculo => {
          return opcoesCalculo.dimensionado;
        })
      },
      cota_final: {
        required,
        cotaMaximaAssentamento,
        isPositive
      },
      coeficiente: {
        required: requiredIf(opcoesCalculo => {
          return opcoesCalculo.metodo == 4;
        }),
        isPositive
      },
      carga: {
        required: requiredIf(opcoesCalculo => {
          return opcoesCalculo.metodo == 5;
        }),
        isPositive
      },
      sobrecarga: { isPositive }
    }
  },
  props: {
    sondagem: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fundacao: {
      type: Number,
      required: true
    }
  },
  methods: {
    verificarCotaAssentamento(campo) {
      let valorMaximo =
        this.opcoesCalculo.sondagem > 0
          ? this.sondagemEscolhida.nspt.length
          : 0;
      return valorMaximo > campo;
    },
    selecionarSondagem() {
      let sondagem = this.sondagem.find(sondagem => {
        return sondagem.id === this.opcoesCalculo.sondagem;
      });
      if (sondagem !== undefined) {
        this.sondagemEscolhida.nivelagua = sondagem.nivelagua;
        this.sondagemEscolhida.nspt = sondagem.nspt;
        this.opcoesCalculo.cotaMaximaAssentamento = sondagem.nspt.length;
        this.sondagemEscolhida.identificacao = sondagem.identificacao;
      }
    },
    dimensionar() {
      this.$v.opcoesCalculo.$touch();
      if (this.$v.opcoesCalculo.$invalid) {
        return;
      }
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.post(
        "/projeto/" + idProjeto + "/geotecnico/dimensionar",
        this.opcoesCalculo
      )
        .then(resposta => {
          if (resposta.status) {
            this.janelaResultadoDimensionamento = true;
            this.resultadoDimensionamento = resposta.data.retorno;
            this.opcoesCalculo.dimensionado = true;
            this.opcoesCalculo.identificacao =
              resposta.data.retorno.metodo_texto +
              " - " +
              this.sondagemEscolhida.identificacao +
              " - " +
              resposta.data.retorno.cota_final +
              "m";
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    salvarDimensionamento() {
      let idProjeto = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      this.opcoesCalculo.tensao = this.resultadoDimensionamento.tensao;
      if (this.opcoesCalculo.bulbo)
        this.opcoesCalculo.pilar = this.resultadoDimensionamento.pilar.id;
      Axios.post("/projeto/" + idProjeto + "/geotecnico", this.opcoesCalculo)
        .then(resposta => {
          if (resposta.status == 201) {
            this.$eventoHub.$emit("adicionarMensagemAviso", {
              mensagem: "Dimensionamento Salvo!",
              classe: "bg-success"
            });
            this.janelaResultadoDimensionamento = false;
            this.resetFormulario();
          }
        })
        .catch(error => {
          this.$swal(
            "Erro",
            this.gerarMenssagemDeErro(error.response.data.retorno)
          );
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    resetFormulario() {
      this.opcoesCalculo.identificacao = "";
      this.opcoesCalculo.sondagem = 0;
      this.opcoesCalculo.metodo = 0;
      this.opcoesCalculo.bulbo = false;
      this.opcoesCalculo.cota_final = null;
      this.opcoesCalculo.coeficiente = null;
      this.opcoesCalculo.carga = null;
      this.opcoesCalculo.limite_spt = [3, 25];
      this.opcoesCalculo.cotaMaximaPermitida = 0;
      this.opcoesCalculo.dimensionado = false;
      this.opcoesCalculo.sobrecarga = null;
      this.opcoesCalculo.dimensionado = false;
      this.$v.opcoesCalculo.$reset();
    }
  },
  created() {
    let opcoesSondagem = this.sondagem.map(sondagem => {
      return { value: sondagem.id, label: sondagem.identificacao };
    });
    opcoesSondagem.unshift({ value: "0", label: "Selecione uma sondagem" });
    this.opcoesSondagem = opcoesSondagem;
    this.$eventoHub.$on("dimensionarGeotecnico", this.dimensionar);
  },
  destroyed() {
    this.$eventoHub.$off("dimensionarGeotecnico", this.dimensionar);
  },
  watch: {
    janelaResultadoDimensionamento(novoValor) {
      if (!novoValor) {
        this.opcoesCalculo.dimensionado = false;
      }
    },
    fundacao(novoValor) {
      this.resetFormulario();
      this.opcoesCalculo.fundacao = novoValor;
    }
  }
};
</script>

<style scoped>
.c-dark-theme select option {
  background-color: rgba(255, 255, 255, 0.1);
}
.c-dark-theme .form-control > option {
  color: black;
}
</style>
