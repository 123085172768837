<template>
  <div>
    <loader :loading="requisicaoAberta"></loader>
    <CForm>
      <CRow>
        <CCol sm="7">
          <CRow>
            <CCol sm="6">
              <CSelect
                label="Método"
                :options="metodos"
                :value.sync="$v.opcoesCalculo.metodo.$model"
                :isValid="verificarCampo('metodo','opcoesCalculo')"
              ></CSelect>
            </CCol>
            <CCol sm="6">
              <CSelect
                label="Sondagem"
                :options="opcoesSondagem"
                :value.sync="$v.opcoesCalculo.sondagem.$model"
                @change="selecionarSondagem"
                :isValid="verificarCampo('sondagem','opcoesCalculo')"
                :invalidFeedback="obtenhaMensagem(2016)"
              ></CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Cota Incial"
                type="number"
                append="m"
                :value.sync="$v.opcoesCalculo.cota_inicial.$model"
                :isValid="verificarCampo('cota_inicial','opcoesCalculo')"
              ></CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Cota Final"
                type="number"
                append="m"
                :value.sync="$v.opcoesCalculo.cota_final.$model"
                :isValid="verificarCampo('cota_final','opcoesCalculo')"
              ></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="8">
              <CSelect
                label="Estaca"
                :options="estacas"
                :value.sync="$v.opcoesCalculo.estaca.$model"
                :isValid="verificarCampo('estaca','opcoesCalculo')"
              ></CSelect>
            </CCol>
            <CCol sm="4">
              <CInput
                label="Diâmetro"
                type="number"
                append="m"
                step="0.05"
                :value.sync="$v.opcoesCalculo.diametro.$model"
                :isValid="verificarCampo('diametro','opcoesCalculo')"
              ></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CFormGroup description="Limite do NSPT">
                <label slot="label">Limite SPT</label>
                <vue-slider
                  slot="input"
                  v-model="opcoesCalculo.limite_spt"
                  :min="0"
                  :max="100"
                  :tooltip="'active'"
                  :enable-cross="false"
                ></vue-slider>
              </CFormGroup>
            </CCol>
            <CCol sm="6">
              <CFormGroup
                description="Limite máximo da resistencia da ponta em relação ao atrito lateral"
              >
                <label slot="label">Limite da ponta</label>
                <vue-slider
                  slot="input"
                  v-model="opcoesCalculo.ponta"
                  :min="0"
                  :max="1"
                  :interval="0.05"
                  :tooltip="'active'"
                  :enable-cross="false"
                  :tooltip-formatter="formatoTooltipPonta"
                ></vue-slider>
              </CFormGroup>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="5">
          <PerfilSondagem
            v-if="opcoesCalculo.sondagem > 0"
            :sondagemSPT="sondagemEscolhida.nspt"
            :altura="alturaPerfil | inteiro"
            :nivel-agua="sondagemEscolhida.nivelagua | decimal"
            class="perfil-sondagem"
          ></PerfilSondagem>
        </CCol>
      </CRow>
    </CForm>
    <CModal :show="janelaResultadoDimensionamento" size="lg">
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Geotécnico
          <CButtonClose
            @click="janelaResultadoDimensionamento = false"
            buttonClasses="text-white close"
          />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <loader :loading="requisicaoAberta"></loader>
        <CCardBody class="overflow-auto">
          <CForm>
            <CInput label="Identificação" :value.sync="opcoesCalculo.identificacao" />
          </CForm>
          <CDataTable :fields="camposTabelaResultado" :items="resultadoDimensionamento">
            <td
              slot="mediaSptPonta"
              slot-scope="{item}"
              class="text-center"
            >{{item.mediaSptPonta | precisao(1)}}</td>
            <td slot="solo" slot-scope="{item}">{{obtenhaSoloTexto(item.solo)}}</td>
            <td slot="qp" slot-scope="{item}" class="text-center">{{item.qp | precisao(2)}}</td>
            <td slot="rp" slot-scope="{item}" class="text-center">{{item.rp | precisao(2)}}</td>
            <td slot="rl" slot-scope="{item}" class="text-center">{{item.rl | precisao(2)}}</td>
            <td slot="qs" slot-scope="{item}" class="text-center">{{item.qs | precisao(2)}}</td>
            <td slot="plUnit" slot-scope="{item}" class="text-center">{{item.plUnit | precisao(2)}}</td>
            <td slot="pl" slot-scope="{item}" class="text-center">{{item.pl | precisao(2)}}</td>
            <td
              slot="ppIntegral"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppIntegral | precisao(2)}}</td>
            <td
              slot="ppPonta"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppPonta | precisao(2)}}</td>
            <td slot="pp" slot-scope="{item}" class="text-center">{{item.pp | precisao(2)}}</td>
            <td
              slot="ppMetodoFsM"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppMetodoFsM | precisao(2)}}</td>
            <td
              slot="ppMetodoFsG"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppMetodoFsG | precisao(2)}}</td>

            <td
              slot="ppMetodo"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppMetodo | precisao(2)}}</td>
            <td
              slot="ppConcreto"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppConcreto | precisao(2)}}</td>
            <td slot="AokippProjeto" slot-scope="{item}" class="text-center">
              <b>{{item.AokippProjeto | precisao(2)}}</b>
            </td>
            <td slot="AokippProjetoTracao" slot-scope="{item}" class="text-center">
              <b>{{item.AokippProjetoTracao | precisao(2)}}</b>
            </td>
            <td slot="DecourtppProjeto" slot-scope="{item}" class="text-center">
              <b>{{item.DecourtppProjeto | precisao(2)}}</b>
            </td>
            <td slot="DecourtProjetoTracao" slot-scope="{item}" class="text-center">
              <b>{{item.DecourtProjetoTracao | precisao(2)}}</b>
            </td>
            <td slot="ppProjeto" slot-scope="{item}" class="text-center">
              <b>{{item.ppProjeto | precisao(2)}}</b>
            </td>
            <td
              slot="ppProjetoTracao"
              slot-scope="{item}"
              class="text-center"
            >{{item.ppProjetoTracao | precisao(2)}}</td>
          </CDataTable>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="salvarDimensionamento">
            <CIcon name="cil-save"></CIcon>&nbsp;Salvar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
const isPositive = value => value == null || parseFloat(value) > 0;
const cotaMaximaAssentamento = (valor, vm) =>
  vm.cotaMaximaAssentamento >= valor;
import {
  required,
  requiredIf,
  between,
  decimal
} from "vuelidate/lib/validators";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import Axios from "axios";
import PerfilSondagem from "./../Sondagem/PerfilSondagem";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Meta from "./../../shared/meta-dados";
import {
  camposDecourt,
  camposAoki,
  camposMediaAokiDecourt
} from "./camposTabelaResultado";

export default {
  name: "SBFomrularioGeotecnicoIndireta",
  components: { Loader, PerfilSondagem, VueSlider },
  mixins: [mixin],
  data() {
    return {
      requisicaoAberta: false,
      metodos: [
        { value: "0", label: "Selecione o método" },
        { value: 301, label: "Aoki & Veloso" },
        { value: 302, label: "Décourt & Quaresma" },
        { value: 303, label: "Média Aoki & Veloso / Décourt & Quaresma" }
      ],
      estacas: [],
      opcoesSondagem: [],
      formatoTooltipPonta: v => Math.round(v * 100) + "%",
      opcoesCalculo: {
        identificacao: "",
        fundacao: 3,
        sondagem: 0,
        metodo: 0,
        cota_inicial: null,
        cota_final: null,
        diametro: null,
        estaca: null,
        limite_spt: [3, 25],
        ponta: 0.2,
        cotaMaximaPermitida: 0,
        dimensionado: false
      },
      alturaPerfil: 570,
      sondagemEscolhida: {
        nspt: [],
        nivelagua: 0
      },
      janelaResultadoDimensionamento: false,
      resultadoDimensionamento: []
    };
  },
  validations: {
    opcoesCalculo: {
      sondagem: { required, isPositive },
      metodo: { required, between: between(300, 303) },
      identificacao: {
        required: requiredIf(opcoesCalculo => {
          return opcoesCalculo.dimensionado;
        })
      },
      cota_final: {
        required,
        cotaMaximaAssentamento,
        isPositive
      },
      cota_inicial: {
        required,
        decimal
      },
      diametro: {
        required,
        decimal,
        isPositive
      },
      estaca: {
        required
      },
      ponta: {
        required,
        decimal
      }
    }
  },
  props: {
    sondagem: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    verificarCotaAssentamento(campo) {
      let valorMaximo =
        this.opcoesCalculo.sondagem > 0
          ? this.sondagemEscolhida.nspt.length
          : 0;
      return valorMaximo > campo;
    },
    selecionarSondagem() {
      let sondagem = this.sondagem.find(sondagem => {
        return sondagem.id === this.opcoesCalculo.sondagem;
      });
      if (sondagem !== undefined) {
        this.sondagemEscolhida.nivelagua = sondagem.nivelagua;
        this.sondagemEscolhida.nspt = sondagem.nspt;
        this.opcoesCalculo.cotaMaximaAssentamento = sondagem.nspt.length;
        this.sondagemEscolhida.identificacao = sondagem.identificacao;
      }
    },
    dimensionar() {
      this.$v.opcoesCalculo.$touch();
      if (this.$v.opcoesCalculo.$invalid) {
        return;
      }
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.post(
        "/projeto/" + idProjeto + "/geotecnico/dimensionar",
        this.opcoesCalculo
      )
        .then(resposta => {
          if (resposta.status) {
            this.janelaResultadoDimensionamento = true;
            this.resultadoDimensionamento = this.processarResultadoDimensionamento(
              resposta.data.retorno
            );
            this.opcoesCalculo.dimensionado = true;
            this.opcoesCalculo.tensao = resposta.data.retorno.tensao;
            this.opcoesCalculo.identificacao =
              resposta.data.retorno.metodo_texto +
              " - " +
              this.sondagemEscolhida.identificacao +
              " - Ø " +
              resposta.data.retorno.diametro +
              "m";
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    processarResultadoDimensionamento(retorno) {
      let retornoDimensionamento = Object.entries(retorno.tensoes);
      let resultados = retornoDimensionamento.map((resultado, index) => {
        resultado[1].cota =
          resultado[1].cota_inicial + " - " + resultado[1].cota_final;
        resultado[1].idLinha = index + 1;
        return resultado[1];
      });
      return resultados;
    },
    salvarDimensionamento() {
      let idProjeto = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      Axios.post("/projeto/" + idProjeto + "/geotecnico", this.opcoesCalculo)
        .then(resposta => {
          if (resposta.status == 201) {
            this.$eventoHub.$emit("adicionarMensagemAviso", {
              mensagem: "Dimensionamento Salvo!",
              classe: "bg-success"
            });
            this.janelaResultadoDimensionamento = false;
          }
        })
        .catch(error => {
          this.$swal(
            "Erro",
            this.gerarMenssagemDeErro(error.response.data.retorno)
          );
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    obtenhaSoloTexto(codigo) {
      return Meta.obtenhaSolos[codigo];
    }
  },
  created() {
    let opcoesSondagem = this.sondagem.map(sondagem => {
      return { value: sondagem.id, label: sondagem.identificacao };
    });
    opcoesSondagem.unshift({ value: "0", label: "Selecione uma sondagem" });
    this.opcoesSondagem = opcoesSondagem;
    this.$eventoHub.$on("dimensionarGeotecnico", this.dimensionar);
    let estacas = Object.entries(Meta.obtenhaEstacas).map(estaca => {
      return { value: estaca[0], label: estaca[1] };
    });
    estacas.unshift({ value: "0", label: "Selecione a estaca" });
    this.estacas = estacas;
  },
  destroyed() {
    this.$eventoHub.$off("dimensionarGeotecnico", this.dimensionar);
  },
  watch: {
    janelaResultadoDimensionamento(novoValor) {
      if (!novoValor) {
        this.opcoesCalculo.dimensionado = false;
      }
    }
  },
  computed: {
    camposTabelaResultado() {
      if (this.opcoesCalculo.metodo == 301) return camposAoki;
      if (this.opcoesCalculo.metodo == 302) return camposDecourt;
      return camposMediaAokiDecourt;
    }
  }
};
</script>

<style>
.c-dark-theme select option {
  background-color: rgba(255, 255, 255, 0.1);
}
.c-dark-theme .form-control > option {
  color: black;
}
</style>
