export const camposDecourt = [
  {
    key: "idLinha",
    sortable: false,
    label: "#",
    _classes: "text-center"
  },
  {
    key: "cota",
    sortable: false,
    label: "Cota",
    _classes: "text-center"
  },
  { key: "spt", sortable: false, label: "SPT", _classes: "text-center" },
  {
    key: "solo",
    sortable: false,
    label: "Solo",
    _classes: "text-center"
  },
  {
    key: "k",
    sortable: false,
    label: "k(kN/m²)",
    _classes: "text-center"
  },
  {
    key: "alfa",
    sortable: false,
    label: "α",
    _classes: "text-center"
  },
  {
    key: "beta",
    sortable: false,
    label: "β",
    _classes: "text-center"
  },
  {
    key: "mediaSptPonta",
    sortable: false,
    label: "Média SPT",
    _classes: "text-center"
  },
  {
    key: "qp",
    sortable: false,
    label: "Qp(kN/m²)",
    _classes: "text-center"
  },

  {
    key: "qs",
    sortable: false,
    label: "Qs(kN/m²)",
    _classes: "text-center"
  },

  {
    key: "plUnit",
    sortable: false,
    label: "Atrito Camada (kN)",
    _classes: "text-center"
  },
  {
    key: "pl",
    sortable: false,
    label: "Atrito Acumulado (kN)",
    _classes: "text-center"
  },
  {
    key: "ppIntegral",
    sortable: false,
    label: "Ponta (kN)",
    _classes: "text-center"
  },
  {
    key: "ppPonta",
    sortable: false,
    label: "Ponta limite (kN)",
    _classes: "text-center"
  },
  {
    key: "pp",
    sortable: false,
    label: "Carga Ponta (kN)",
    _classes: "text-center"
  },
  {
    key: "ppMetodoFsM",
    sortable: false,
    label: "Carga Projeto(Fs do Método)(kN)",
    _classes: "text-center"
  },
  {
    key: "ppMetodoFsG",
    sortable: false,
    label: "Carga Projeto(Fs do Global)(kN)",
    _classes: "text-center"
  },
  {
    key: "ppConcreto",
    sortable: false,
    label: "Carga Projeto Concreto(kN)",
    _classes: "text-center"
  },
  {
    key: "ppProjeto",
    sortable: false,
    label: "Carga Projeto Adotado(kN)",
    _classes: "text-center"
  },

  {
    key: "ppProjetoTracao",
    sortable: false,
    label: "Carga Projeto Tração Adotado(kN)",
    _classes: "text-center"
  }
]

export const camposAoki = [
  {
    key: "idLinha",
    sortable: false,
    label: "#",
    _classes: "text-center"
  },
  {
    key: "cota",
    sortable: false,
    label: "Cota",
    _classes: "text-center"
  },
  { key: "spt", sortable: false, label: "SPT", _classes: "text-center" },
  {
    key: "solo",
    sortable: false,
    label: "Solo",
    _classes: "text-center"
  },
  {
    key: "ka",
    sortable: false,
    label: "k(kN/m²)",
    _classes: "text-center"
  },
  {
    key: "alfa",
    sortable: false,
    label: "α (%)",
    _classes: "text-center"
  },
  {
    key: "f1",
    sortable: false,
    label: "F1",
    _classes: "text-center"
  },
  {
    key: "f2",
    sortable: false,
    label: "F2",
    _classes: "text-center"
  },
  {
    key: "rp",
    sortable: false,
    label: "Rp(kN/m²)",
    _classes: "text-center"
  },

  {
    key: "rl",
    sortable: false,
    label: "Rl(kN/m)",
    _classes: "text-center"
  },

  {
    key: "plUnit",
    sortable: false,
    label: "Atrito Camada (kN)",
    _classes: "text-center"
  },
  {
    key: "pl",
    sortable: false,
    label: "Atrito Acumulado (kN)",
    _classes: "text-center"
  },
  {
    key: "ppIntegral",
    sortable: false,
    label: "Ponta (kN)",
    _classes: "text-center"
  },
  {
    key: "ppPonta",
    sortable: false,
    label: "Ponta limite (kN)",
    _classes: "text-center"
  },
  {
    key: "pp",
    sortable: false,
    label: "Carga Ponta (kN)",
    _classes: "text-center"
  },
  {
    key: "ppMetodo",
    sortable: false,
    label: "Carga Projeto(Fs Global)(kN)",
    _classes: "text-center"
  },
  {
    key: "ppConcreto",
    sortable: false,
    label: "Carga Projeto Concreto(kN)",
    _classes: "text-center"
  },
  {
    key: "ppProjeto",
    sortable: false,
    label: "Carga Projeto Adotado(kN)",
    _classes: "text-center"
  },

  {
    key: "ppProjetoTracao",
    sortable: false,
    label: "Carga Projeto Tração Adotado(kN)",
    _classes: "text-center"
  }
]

export const camposMediaAokiDecourt = [
  {
    key: "idLinha",
    sortable: false,
    label: "#",
    _classes: "text-center"
  },
  {
    key: "cota",
    sortable: false,
    label: "Cota",
    _classes: "text-center"
  },
  { key: "spt", sortable: false, label: "SPT", _classes: "text-center" },
  {
    key: "solo",
    sortable: false,
    label: "Solo",
    _classes: "text-center"
  },
  {
    key: "ppConcreto",
    sortable: false,
    label: "Carga Projeto Concreto(kN)",
    _classes: "text-center"
  },
  {
    key: "AokippProjeto",
    sortable: false,
    label: "Carga Projeto Aoki(kN)",
    _classes: "text-center"
  },
  {
    key: "AokippProjetoTracao",
    sortable: false,
    label: "Carga Projeto Tração Aoki(kN)",
    _classes: "text-center"
  },
  {
    key: "DecourtppProjeto",
    sortable: false,
    label: "Carga Projeto Decourt(kN)",
    _classes: "text-center"
  },
  {
    key: "DecourtProjetoTracao",
    sortable: false,
    label: "Carga Projeto Tração Decourt(kN)",
    _classes: "text-center"
  },
  {
    key: "ppProjeto",
    sortable: false,
    label: "Carga Projeto Adotado(kN)",
    _classes: "text-center"
  },

  {
    key: "ppProjetoTracao",
    sortable: false,
    label: "Carga Projeto Tração Adotado(kN)",
    _classes: "text-center"
  }
]
