<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-vertical-align-bottom" />&nbsp;Dimensionamento Geotecnico
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable :fields="camposTabelaGeotenico" :items="geotenicos">
          <template slot="no-items-view">
            <div class="text-center my-5">
              <h2 v-if="!requisicaoAberta">Nenhum dimensionamento geotécnico encontrado!!!</h2>
            </div>
          </template>
          <td slot="idLinha" slot-scope="{item, index}" class="text-center">{{index+1}}</td>
          <td
            slot="sondagem"
            slot-scope="{item}"
          >{{item.sondagem == null ? "Não Encontrada!" : item.sondagem.identificacao}}</td>
          <td
            slot="tipofundacao"
            slot-scope="{item}"
            class="text-center"
          >{{otenhaFundacao(item.fundacao)}}</td>
          <td
            slot="cota_inicial"
            slot-scope="{item}"
            class="text-center"
          >{{item.cota_inicial | decimal}}</td>
          <td
            slot="cota_final"
            slot-scope="{item}"
            class="text-center"
          >{{item.cota_final | decimal}}</td>
          <td
            slot="tensao"
            slot-scope="{item}"
            class="text-center"
          >{{tensaoMaxima(item)}} {{item.fundacao == 3 ? 'kN' : 'kPa'}}</td>
          <td slot="acoes" class="text-center" slot-scope="{item}">
            <CButtonGroup size="sm">
              <CButton color="danger" @click="excluirGeotecnico(item)">
                <CIcon name="cil-trash" />
              </CButton>
              <CButton color="info">
                <CIcon name="cil-folder-open" />
              </CButton>
            </CButtonGroup>
          </td>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CDropdown color="success" class="float-right" size="sm">
          <template slot="toggler-content">
            <CIcon name="cil-notes"></CIcon>&nbsp;Novo
          </template>
          <CDropdownItem @click="novoDimensionamento(1)">Sapata</CDropdownItem>
          <CDropdownItem @click="novoDimensionamento(2)">Tubulão</CDropdownItem>
          <CDropdownItem @click="novoDimensionamento(3)">Estaca</CDropdownItem>
        </CDropdown>
      </CCardFooter>
    </CCard>

    <CModal
      :show.sync="formularioCadastroGeotecnico"
      size="lg"
      addContentClasses="card"
      :closeOnBackdrop="false"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Geotécnico
          <small>{{otenhaFundacao(this.fomrularioCadastroTipo)}}</small>
          <CButtonClose
            @click="formularioCadastroGeotecnico = false"
            buttonClasses="text-muted close"
          />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <SBFomrularioGeotecnicoDireta
            v-if="fomrularioCadastroTipo==1 || fomrularioCadastroTipo ==2"
            :sondagem="this.sondagensProjeto"
            :fundacao="fomrularioCadastroTipo"
          ></SBFomrularioGeotecnicoDireta>
          <SBFomrularioGeotecnicoIndireta
            v-if="fomrularioCadastroTipo==3"
            :sondagem="this.sondagensProjeto"
            :fundacao="fomrularioCadastroTipo"
          ></SBFomrularioGeotecnicoIndireta>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="emitirEventoDimensionar">
            <CIcon name="cil-calculator"></CIcon>&nbsp;Dimensionar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
import Meta from "./../../shared/meta-dados";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import Axios from "axios";
import SBFomrularioGeotecnicoDireta from "./FormularioFundacaoDireta";
import SBFomrularioGeotecnicoIndireta from "./FormularioFundacaoIndireta";

export default {
  name: "Geotecnico",
  components: {
    Loader,
    SBFomrularioGeotecnicoDireta,
    SBFomrularioGeotecnicoIndireta
  },
  mixins: [mixin],
  data() {
    return {
      requisicaoAberta: false,
      geotenicos: [],
      formularioCadastroGeotecnico: false,
      fomrularioCadastroTipo: 0,
      sondagensProjeto: false,
      camposTabelaGeotenico: [
        { key: "idLinha", sortable: true, label: "#", _classes: "text-center" },
        { key: "identificacao", sortable: true, label: "Identificação" },
        { key: "sondagem", sortable: true, label: "Sondagem" },
        {
          key: "tipofundacao",
          sortable: true,
          label: "Tipo Fundação",
          _classes: "text-center"
        },
        {
          key: "cota_inicial",
          sortable: true,
          label: "Cota Inicial (m)",
          _classes: "text-center"
        },
        {
          key: "cota_final",
          sortable: true,
          label: "Cota Assentamento (m)",
          _classes: "text-center"
        },
        {
          key: "tensao",
          sortable: true,
          label: "Tensão Máxima (kN)",
          _classes: "text-center"
        },
        {
          key: "acoes",
          sortable: false,
          label: "Ação",
          _classes: "text-center"
        }
      ]
    };
  },

  methods: {
    obtenhaGeotecnicos() {
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.get("/projeto/" + idProjeto + "/geotecnico")
        .then(resposta => {
          let geotenicos = resposta.data.retorno.map(geotenico => {
            let classe = geotenico.resultado.erro ? "table-danger" : false;
            return Object.assign(geotenico, { _classes: classe });
          });
          this.geotenicos = geotenicos;
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(async () => {
          await this.$nextTick();
          this.requisicaoAberta = false;
        });
    },
    tensaoMaxima(item) {
      if (item.tensao === null || item.tensao === undefined) return "---";
      if (item.tensao !== null)
        return Number.parseFloat(item.tensao).toFixed(2);
      let tensaoMaxima = Object.values(item.resultado.tensao).sort((a, b) => {
        return b.ppProjeto - a.ppProjeto;
      });
      return Number.parseFloat(tensaoMaxima[0]["ppProjeto"]).toFixed(2);
    },
    excluirGeotecnico(item) {
      let idProjeto = this.obtenhaIdProjeto();
      this.$swal({
        title: "Atenção",
        icon: "warning",
        text: Meta.obtenhaMensagem(1011, "pt-br"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.requisicaoAberta = true;
          Axios.delete("/projeto/" + idProjeto + "/geotecnico/" + item.id)
            .then(resposta => {
              if (resposta.status) {
                this.$eventoHub.$emit("adicionarMensagemAviso", {
                  mensagem: resposta.data.retorno,
                  classe: "bg-success"
                });
                this.obtenhaGeotecnicos();
              }
            })
            .catch(erro => {
              this.$eventoHub.$emit("adicionarMensagemAviso", {
                mensagem: erro.response.data.retorno,
                classe: "bg-danger"
              });
            })
            .finally(async () => {
              this.requisicaoAberta = false;
            });
        }
      });
    },
    obtenhaSondagem() {
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.get("/projeto/" + idProjeto + "/sondagem")
        .then(response => {
          this.sondagensProjeto = response.data.retorno;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    novoDimensionamento(fundacao) {
      this.fomrularioCadastroTipo = fundacao;
      this.formularioCadastroGeotecnico = true;
    },
    emitirEventoDimensionar() {
      this.$eventoHub.$emit("dimensionarGeotecnico", true);
    }
  },
  created() {
    this.obtenhaGeotecnicos();
    this.obtenhaSondagem();
  },
  watch: {
    formularioCadastroGeotecnico(novoValor) {
      if (!novoValor) this.obtenhaGeotecnicos();
    }
  }
};
</script>
<style>
.modal-content {
  max-height: 90vh;
}
</style>

